/* You can add global styles to this file, and also import other style files */
@import "~bootstrap-icons/font/bootstrap-icons.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.page-title {
  text-align: center;
  margin: 2em;
  font-size: 20px;
}

.gallery {
  columns: 24rem;
  span {
    margin: 12px auto;
  }
}

.gallery-item {
  padding: 6px;
  transition: 0.4s;
  margin-bottom: 30px
}

.gallery-item:hover {
    padding: 0;
}

span {
  display: inline-block;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}